<template>
  <div class="event-info">
    <div class="info-items">
      <div class="plate">
        <div  class="plate-icon">
        <img v-if="event.triggered[0].classification === 3" src="@/assets/icons/Car_L.svg" alt="">
        <img v-else-if="event.triggered[0].classification === 4" src="@/assets/icons/Motor_L.svg" alt="">
        <img v-else src="@/assets/icons/CarPlate.svg" alt="">
        </div>
        <div class="plate-code">{{ event.triggered[0].content }}</div>
      </div>
      <div class="time">
        <template v-if="isUrgent">
          <span>{{ chasingStartTime }}</span> ~ <span>{{ chasingEndTime }}</span>
        </template>
        <span v-else>{{ detectTime }}</span>
      </div>
      <div v-if="isUrgent" class="chased-event">
        <TooltipElement :content="$t('event_chasing')/*圍捕*/" alwaysShow>
          <img src="@/assets/icons/urgent-s.svg" alt="">
        </TooltipElement>
        <span>{{ $t('event_chasing')/*圍捕*/ }}</span>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('device')/*設備*/ }}</div>
        <div class="items">
          <div class="item">
            <img src="@/assets/icons/Group.svg" alt="">
            <span class="group-name">{{ getGroupName(event.groupId) }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <span>{{ getEventTitleId(event.user.id) }}</span>  
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_fr_gps')/*GPS位置*/" alwaysShow>
              <img src="@/assets/icons/gps.svg" alt="">
            </TooltipElement>
            <span>{{ event.latitude }}, {{ event.longitude }}</span>
            <img src="@/assets/icons/google-map-icon.png" @click="goGoogleMap" alt="">
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('vehicle')/*車輛*/ }}</div>
        <div class="items">
          <div class="item">
            <TooltipElement :content="$t('history_tag')/*車輛標記*/" alwaysShow>
              <img src="@/assets/icons/tag-s.svg" alt="">
            </TooltipElement>
            <div class="tags">
              <span v-for="(tag, index) in getTagNameList(event.triggered[0].tag)" :key="index">{{ tag }}</span>
            </div>
          </div>
          <div class="item">
            <TooltipElement :content="$t('event_notes')/*備註*/" alwaysShow>
              <img src="@/assets/icons/car-note.svg" alt="">
            </TooltipElement>
            <span>{{ event.triggered[0].note }}</span>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('history_mission')/*任務*/ }}</div>
        <div class="items">
          <div class="item">
            <TooltipElement :content="$t('history_mission')/*任務*/" alwaysShow>
              <img v-if="event.missionCode > 0" :src="missionIcon" alt="">
              <img v-else src="@/assets/icons/mission.svg" alt="">
            </TooltipElement>
            
            <span v-if="event.missionCode > 0">{{ codeBooks.mission[event.missionCode] }}
              <span v-if="event.missioned === 3">{{ $t('finish')/*完成*/ }}</span>
            </span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_push_message')/*推送訊息*/" alwaysShow>
              <img src="@/assets/icons/note.svg" alt="">
            </TooltipElement>
            <span ref="missionNotice" @mouseover="onMouseOver('missionNotice')">{{ event.missionNotice }}</span>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="group-title">{{ $t('link')/*勾稽*/ }}</div>
        <div class="items">
          <div class="item">
            <TooltipElement :content="$t('history_user_group')/*使用者群組*/" alwaysShow>
              <img src="@/assets/icons/Group.svg" alt="">
            </TooltipElement>
            <span class="group-name">{{ event.linkedGroupName }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_link_user')/*勾稽使用者*/" alwaysShow>
              <img src="@/assets/icons/user.svg" alt="">
            </TooltipElement>
            <span v-if="event.linkedUserName">{{ `${ event.linkedUserName } (${ event.linkedUserAccount })` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { apiEditBoviaLprEvent } from '@/api/index.js'

export default {
  name: 'EventInfo',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object
    },
  },
  data() {
    return {
      isShowTooltip: false,
    }
  },
  computed: {
    ...mapState(['codeBooks', 'tagList', 'groupList']),
    ...mapGetters(['getEventTitleId', 'timezone']),
    detectTime() {
      return moment(this.event.detectTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    chasingStartTime() {
      if (!this.event.chasingStartTime) return ''
      return moment(this.event.chasingStartTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    chasingEndTime() {
      if (!this.event.chasingEndTime) return ''
      return moment(this.event.chasingEndTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    isUrgent() {
      return this.event.chasing == 1 ||
        (this.event.chasingStartTime && this.event.chasingEndTime)
        ? true
        : false
    },
    missionIcon() {
      if (this.event.missionCode === 1) 
        return require('@/assets/icons/cofind.svg')
      else if (this.event.missionCode === 2)
        return require('@/assets/icons/missionCheck.svg')
      else if (this.event.missionCode === 3) 
        return require('@/assets/icons/arrest.svg') 
      else 
        return require('@/assets/icons/cofind.svg')
    },
  },
  methods: {
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.event.latitude},${this.event.longitude}`
      window.open(url, '_blank')
    },
    getTag(arrTag) {
      let tags = ''
      arrTag.forEach((item) => {
        let idx = this.tagList.findIndex((obj) => obj.id === item)
        if (idx !== -1) {
          let seperator = tags == '' ? '' : ' / '
          tags += seperator + this.tagList[idx].name
        }
      })
      return tags
    },
    getTagNameList(arrTag) {
      if (!arrTag || arrTag.length <= 0) {
        return [this.$t('no_subscription')]
      }
      return arrTag.map(item => {
        let objTag = this.tagList.find(obj => obj.id === item)
        return objTag ? objTag.name : ''
      })
    },
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
    onMouseOver(str) {
      const tag = this.$refs[str]
      const contentWidth = tag.offsetWidth
      const contentHeight = tag.offsetHeight
      const scrollWidth = tag.scrollWidth
      const scrollHeight = tag.scrollHeight
      this.isShowTooltip = contentWidth < scrollWidth || contentHeight < scrollHeight
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.event-info {
  width: 100%;
  height: 100%;
  padding-right: 20px;
  display: flex;
  column-gap: 32px;
  box-sizing: border-box;

  .info-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: px2rem(20);
    line-height: 36px;
    @include xxl {
      font-size: 20px;
      line-height: 30px;
    }
  }

  span {
    font-size: px2rem(20);
    @include xxl {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .plate {
    position: relative;
    display: flex;
    align-items: center;
  }

  .plate-code {
    font-size: px2rem(24);
    font-weight: 700;
    line-height: 33px;
    color: #FFE99F;
    @include xxl {
      font-size: 24px;
    }
  }

  .plate-icon {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  .time {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  .time span {
    display: inline-block;
    background: #4A5C78;
    border-radius: 15px;
    padding: 2px 12px 3px;
    font-size: px2rem(18);
    line-height: 24px;
  }

  .chased-event {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    font-size: px2rem(20);
    @include xxl {
      font-size: 20px;
    }
  }

  .info img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .subject {
    font-size: 20px;
    line-height: 24px;
    color: #FFE99F;
    margin-top: 8px;
    @include xxl {
      font-size: 15px;
    }
  }

  .group-name {
    font-weight: 400;
    color: #FFC600;
    white-space: nowrap;
    margin-right: 8px;
  }

  .video-title {
    display: flex;
    width: 100%;
    font-weight: 300;
    span {
      width: 100%;
      font-weight: 300;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .gps img ~ img {
    margin-left: 20px;
    cursor: pointer;
  }

  

  .note span {
    width: 100%;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .separater {
    width: 100%;
    height: 2px;
    border-top: 1px solid #ffffff33;
  }

  .message {
    display: flex;
    align-items: center;
  }

  .push-content span {
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

.group {
  display: flex;
  column-gap: 20px;
  padding: 8px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #ffffff33;
  }
  
  .group-title {
    width: px2rem(80);
    font-size: px2rem(20);
    line-height: px2rem(24);
    color: #FFE99F;
    font-weight: 400;
  }
  .items {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .item {
      display: flex;
      align-items: flex-start;
      column-gap: 12px;
      font-size: px2rem(20);
      line-height: px2rem(32);
      color: #ffffff;
      font-weight: 400;
      img {
        width: px2rem(24);
        height: px2rem(24);
        margin-top: px2rem(5);
      }
      span {
        font-size: px2rem(20);
      }
    }
  }
}

.tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  margin-bottom: 4px;
}

.tags span {
  display: flex;
  align-items: center;
  background: #ffffff33;
  padding: 1px 12px 2px 12px;
  border-radius: 20px;
  margin-right: 8px;
  white-space: nowrap;
}
</style>